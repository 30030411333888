import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import DashboardBanner from "../../../components/ui-elements/banner/dashbaord-banner";
import KPISheetModal from "../../../components/ui-elements/modals/kpis-modals/kpi-sheet-modal";
import BoxCard01 from "../../../components/ui-elements/cards/box-card-01";
import BoxTableCard01 from "../../../components/ui-elements/cards/table-cards/box-table-card-01";

function DashboardCustomerPage(props) {
  const { dearCustomerWithoutTags,

    dearProductWithoutTags,
    dearLoadingStatuses,
    xeroKPIFigures, isLoadingKPI
  } =
    useSelector((state) => state.kpi);
  const { organisation } = useSelector((state) => state.organisation);

  // BOX KPIs

  const getKPIFigure = (kpiName) => {
    if (Array.isArray(xeroKPIFigures)) {
      const figure = xeroKPIFigures.find((item) => item.KPI === kpiName);
      return figure || null;
    } else {
      return null;
    }
  };
  const totalCustomersFigureData = getKPIFigure("Total Customers");

  const [toggleKPISheet, setKPISheetToggle] = useState(false);
  const [kpiSheetTitle, setKPISheetTitle] = useState("");

  const extractFilters = (kpiTitle) => {
    const savedFilters = organisation?.saved_filters[organisation?.name];
    const filter = savedFilters?.find((filter) => filter.kpi === kpiTitle);
    if (filter) {
      return {
        firstFilter: filter.first_filter,
        comparedTo: filter.compared_to,
      };
    }
    return { firstFilter: null, comparedTo: null };
  };

  // Extract saved filters for each KPI
  const totalCustomersSavedFilters = extractFilters("Total Customers");
  const toggleKPI = (title) => {
    setKPISheetTitle(title);
    setKPISheetToggle(!toggleKPISheet);
  };

  const xeroKPIComponent = (
    <p></p>
  )
  const dearKPIComponent = (
    <div class="mx-0 mt-5 flex flex-row gap-2">

     <BoxTableCard01
     isDateFilters={false}
    title={dearCustomerWithoutTags?.results?.[0]?.kpi || 'Customers Without Tags'}
    tooltip={dearCustomerWithoutTags?.results?.[0]?.description || 'Customers who do not have a customer tag in Cin7 Core'}
    isLoading={dearLoadingStatuses.dearOtherKPIs}
    tableData={dearCustomerWithoutTags?.results?.[0]?.data}/>
    <BoxTableCard01
    isDateFilters={false}
    title={dearProductWithoutTags?.results?.[0]?.kpi || 'Products Without Tags'}
    tooltip={dearProductWithoutTags?.results?.[0]?.description || 'List of products with no defined product tag in Cin7 Core'}
    isLoading={dearLoadingStatuses.dearTables}
    tableData={dearProductWithoutTags?.results?.[0]?.data}/>
  </div>
  )
  const displayKPIComponent = (item) => {
    switch (item) {
      case 'XERO':
        return xeroKPIComponent
      case 'DEAR':
        return dearKPIComponent
      default:
        return 'Unknown';
    }
  };
  return (
    <div className="w-full h-full px-5 py-5 bg-light-white flex flex-col">
      <div className="bg-white flex-1 p-5 border-2 border-light-grey mt-5 justify-center items-start">
        <DashboardBanner title="Customers" isGlobalFilterRequired={false} />
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
          <div class="rounded-md">
            <BoxCard01
              toggleKPI={false}
              customSymbol={""}
              isCustomSymbol={true}
              title="Total Customers"
              data={totalCustomersFigureData}
              savedFilterA={totalCustomersSavedFilters.firstFilter}
              savedFilterB={totalCustomersSavedFilters.comparedTo}
              sparkLine01Data={""}
              sparkLine02Data={""}
            />
          </div>
        </div>
        <KPISheetModal
          toggle={toggleKPISheet}
          toggleModal={toggleKPI}
          kpiName={kpiSheetTitle}
        >
          <p className="font-bold text-lg">{kpiSheetTitle}</p>

        </KPISheetModal>
        {organisation?.connected_apps && organisation.connected_apps.map((item, index) => (
          <div key={index}>
            {displayKPIComponent(item)}
          </div>
        ))}
      </div>
    </div>
  );
}

export default DashboardCustomerPage;
