import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import DashboardBanner from "../../../components/ui-elements/banner/dashbaord-banner";
import KPISheetModal from "../../../components/ui-elements/modals/kpis-modals/kpi-sheet-modal";

import BoxTableCard01 from "../../../components/ui-elements/cards/table-cards/box-table-card-01";

function DashboardPurchasingPage(props) {
  const {
    dearTopLateSuppliers,
    dearTopTenSuppliersByCost,
    dearTopTenSuppliersByVolume,
    dearTopTenSuppliersByShipment,
    dearLoadingStatuses,
  } = useSelector((state) => state.kpi);
  const { organisation } = useSelector((state) => state.organisation);
  const [toggleKPISheet, setKPISheetToggle] = useState(false);
  const [kpiSheetTitle, setKPISheetTitle] = useState("");

  const toggleKPI = (title) => {
    setKPISheetTitle(title);
    setKPISheetToggle(!toggleKPISheet);
  };

  const xeroKPIComponent = <p></p>;
  const dearKPIComponent = (
    <>
      <div class="mx-0 mt-5 flex flex-row gap-4">
        <BoxTableCard01
        isDateFilters={true}
          title={dearTopLateSuppliers?.results?.[0]?.kpi || 'Top 10 Late Suppliers'}
          tooltip={dearTopLateSuppliers?.results?.[0]?.description || 'Percentage of orders which are late by supplier in Cin7 Core'}
          isLoading={dearLoadingStatuses.dearOtherKPIs}
          tableData={dearTopLateSuppliers?.results?.[0]?.data}
        />
        <BoxTableCard01
        isDateFilters={true}
          title={dearTopTenSuppliersByCost?.results?.[0]?.kpi || 'Top 10 Suppliers By Cost'}
          tooltip={dearTopTenSuppliersByCost?.results?.[0]?.description || 'Sum of purchasing cost for suppliers in Cin7 Core'}
          isLoading={dearLoadingStatuses.dearOtherKPIs}
          tableData={dearTopTenSuppliersByCost?.results?.[0]?.data}
        />
      </div>
      <div class="mx-0 mt-5 flex flex-row gap-4">
        <BoxTableCard01
        isDateFilters={true}
          title={dearTopTenSuppliersByVolume?.results?.[0]?.kpi || 'Top 10 Suppliers By Volume (Based on Invoice)'}
          tooltip={dearTopTenSuppliersByVolume?.results?.[0]?.description || 'Sum of quantity purchased for suppliers in Cin7 Core'}
          isLoading={dearLoadingStatuses.dearOtherKPIs}
          tableData={dearTopTenSuppliersByVolume?.results?.[0]?.data}
        />
          <BoxTableCard01
          isDateFilters={true}
          title={dearTopTenSuppliersByShipment?.results?.[0]?.kpi || 'Top 10 Suppliers By Volume (Based on Shipment)'}
          tooltip={dearTopTenSuppliersByShipment?.results?.[0]?.description || 'Sum of quantity received for suppliers in Cin7 Core'}
          isLoading={dearLoadingStatuses.dearOtherKPIs}
          tableData={dearTopTenSuppliersByShipment?.results?.[0]?.data}
        />
      </div>
    </>
  );
  const displayKPIComponent = (item) => {
    switch (item) {
      case "XERO":
        return xeroKPIComponent;
      case "DEAR":
        return dearKPIComponent;
      default:
        return "Unknown";
    }
  };
  return (
    <div className="w-full h-full px-5 py-5 bg-light-white flex flex-col">
      <div className="bg-white flex-1 p-5 border-2 border-light-grey mt-5 justify-center items-start">
        <DashboardBanner title="Purchasing" isGlobalFilterRequired={false} />
        <KPISheetModal
          toggle={toggleKPISheet}
          toggleModal={toggleKPI}
          kpiName={kpiSheetTitle}
        >
          <p className="font-bold text-lg">{kpiSheetTitle}</p>
        </KPISheetModal>
        {organisation?.connected_apps &&
          organisation.connected_apps.map((item, index) => (
            <div key={index}>{displayKPIComponent(item)}</div>
          ))}
      </div>
    </div>
  );
}

export default DashboardPurchasingPage;
