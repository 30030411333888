import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BoxCard01 from "../../../components/ui-elements/cards/box-card-01";
import DashboardBanner from "../../../components/ui-elements/banner/dashbaord-banner";
import KPISheetModal from "../../../components/ui-elements/modals/kpis-modals/kpi-sheet-modal";
import BoxCard02 from "../../../components/ui-elements/cards/box-card-02";
import GraphData from "./graphs";
import { Link, json } from "react-router-dom";
import HorizontalBarChart from "../../../components/graphs/react-chartjs-two/horizontal-bar-chart-dear";
import LineChartDear from "../../../components/graphs/react-chartjs-two/line-chart-dear";
import LineChart from "../../../components/graphs/react-chartjs-two/line-chart";
import countryToSymbolMap from "../../../../constant/country-code-currency.json";
import BoxTableCard01 from "../../../components/ui-elements/cards/table-cards/box-table-card-01";
import KPIDrillDownModal from "../../../components/ui-elements/modals/kpis-modals/drill-down-modal";

function DashboardInventoryPage(props) {
  const {
    xeroKPIFigures,
    lineGraphs,
    dearUnitsShipped,
    dearInventoryValue,
    dearLoadingStatuses,
    dearCostOfGoodsSold,
    dearNoSalesOrderByCarriers,
    dearPercentageOrderShippedLate,
    dearInventoryValueDrilldown,
    dearIncomingInventoryDrilldown,
    dearSampleShipped,
    dearProductsWithZeroSales,
    dearOutOfStockRate,
    dearIncomingInventory,
  } = useSelector((state) => state.kpi);
  const { organisation } = useSelector((state) => state.organisation);
  const countryCode =
    organisation && organisation.country_alpha3
      ? organisation.country_alpha3.replace(/"/g, "")
      : null;
  const currencySymbol = countryCode ? countryToSymbolMap[countryCode] : null;
  // GRAPHS
  const {
    unitsSoldLineGraphData,
    dearUnitsShippedLineData,
    unitsShippedLineGraphData,
    unitsShippedByCategoryChartData,
    unitsShippedByBrandsChartData,
    unitsShippedByCustomerChartData,
    inventoryLineGraphData,
    inventoryLineData,
  } = GraphData();
  // BOX KPIs

  const getKPIFigure = (kpiName) => {
    if (Array.isArray(xeroKPIFigures)) {
      const figure = xeroKPIFigures.find((item) => item.KPI === kpiName);
      return figure || null; // Return null if the figure is not found
    } else {
      return null; // Return null if xeroKPIFigures is not an array
    }
  };

  const extractFilters = (kpiTitle) => {
    const savedFilters = organisation?.saved_filters[organisation?.name];
    const filter = savedFilters?.find((filter) => filter.kpi === kpiTitle);
    if (filter) {
      return {
        firstFilter: filter.first_filter,
        comparedTo: filter.compared_to,
      };
    }
    return { firstFilter: null, comparedTo: null };
  };

  const inventoryXeroData = getKPIFigure("Inventory");
  const percentageOrderShippedLate = extractFilters("% of Orders Shipped Late");

  // Spark Lines
  function filterLastMonthsData(data, months = 6) {
    if (Array.isArray(data)) {
      return data.slice(0, months);
    } else {
      return [];
    }
  }
  const inventoryXeroFilters = extractFilters("Inventory");

  const extractSparkLineData = (data, key) =>
    data.map((entry) => entry[key] || 0);
  function generateSparkLineData(rawData, key) {
    if (!Array.isArray(rawData) || rawData.length === 0) {
      return [];
    }
    // Sort the data by month in descending order
    const sortedData = rawData.slice().sort((a, b) => {
      if (a.month < b.month) return 1;
      if (a.month > b.month) return -1;
      return 0;
    });

    // Extract the last 6 months' data and reverse it
    const last6MonthsData = filterLastMonthsData(sortedData).reverse();
    const sparkLine = extractSparkLineData(last6MonthsData, key);
    return sparkLine; // Return sparkLine array directly without adding 0
  }

  // Units Shipped
  const dearUnitsShippedSpark = dearUnitsShippedLineData || [];

  const sparkLine01DearUnitsShippedData = generateSparkLineData(
    dearUnitsShippedSpark,
    "current_year_units_shipped"
  );
  const sparkLine02DearUnitsShippedData = generateSparkLineData(
    dearUnitsShippedSpark,
    "previous_year_units_shipped"
  );

  const unitsShippedFilters = extractFilters("Units Shipped");
  const inventoryValueFilters = extractFilters("Inventory Value");
  const dearOutOfStockFilters = extractFilters("Inventory Stockout Rate");
  const dearCostOfGoodsSoldFilters = extractFilters("Cost of Goods Sold");
  const dearIncomingInventoryFilters = extractFilters("Incoming Stock");


  // Advanced Filters
  const [isAdvancedDearFilters, setIsAdvancedDearFilters] = useState(false);
  const [openKPIAdvancedFilters, setOpenKPIAdvancedFilters] = useState({
    "Units Shipped": false,
    "Inventory Stockout Rate": false,
    "Cost of Goods Sold": false,
    "Incoming Stock": false,

  });

  // Function to toggle advanced filters for a specific BoxCard02 component
  const toggleAdvancedFilters = (title) => {
    setOpenKPIAdvancedFilters((prevState) => ({
      ...prevState,
      [title]: !prevState[title],
    }));
  };

  // const inventoryData = Array.isArray(lineGraphs)
  //   ? lineGraphs.find((graph) => graph.graph_KPI_name === "inventory")
  //   : null;
  // let inventoryLineGraphData = {
  //   labels: [],
  //   datasets: [
  //     {
  //       label: "Inventory",
  //       borderColor: "#9C27B0",
  //       backgroundColor: "#E1BEE7",
  //       data: [],
  //     },
  //     {
  //       label: "Previous Year",
  //       borderColor: "#FF9800",
  //       backgroundColor: "#FFE0B2",
  //       data: [],
  //     },
  //   ],
  // };

  // if (inventoryData && Array.isArray(inventoryData.inventory)) {
  //   const formattedInventoryLineGraphData = inventoryData.inventory
  //     .map((item) => {
  //       const date = new Date(item.month);
  //       const monthYearString = new Intl.DateTimeFormat("en", {
  //         year: "numeric",
  //         month: "short",
  //       }).format(date);
  //       return { ...item, month: monthYearString };
  //     })
  //     .sort((a, b) => new Date(a.month) - new Date(b.month));

  //   inventoryLineGraphData = {
  //     labels: formattedInventoryLineGraphData.map((item) => item.month),
  //     datasets: [
  //       {
  //         label: "Inventory",
  //         borderColor: "#9C27B0",
  //         backgroundColor: "#E1BEE7",
  //         data: formattedInventoryLineGraphData.map((item) => item.inventory),
  //       },
  //       {
  //         label: "Previous Year",
  //         borderColor: "#FF9800",
  //         backgroundColor: "#FFE0B2",
  //         data: formattedInventoryLineGraphData.map(
  //           (item) => item.previous_year_inventory
  //         ),
  //       },
  //     ],
  //   };
  // }

  const [toggleKPIDrillDownModal, setKPIDrillDownModal] = useState(false);
  const [drilldownTitle, setDrilldownTitle] = useState("");
  const [drilldownTooltip, setDrilldownTooltip] = useState("");
  const [drilldownTableData, setDrilldownTableData] = useState([]);

  const toggleDrillDownModalSheet = (title, tooltip, data) => {
    setKPIDrillDownModal(!toggleKPIDrillDownModal);
    setDrilldownTitle(title);
    setDrilldownTooltip(tooltip);
    console.log('Data is', data)
    setDrilldownTableData(data);
  };

  const [toggleKPISheet, setKPISheetToggle] = useState(false);
  const [kpiSheetTitle, setKPISheetTitle] = useState("");

  const toggleKPI = (title) => {
    setKPISheetTitle(title);
    setKPISheetToggle(!toggleKPISheet);
  };

  const xeroKPIComponent = (
    <div class="mx-0 ">
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
        <div class=" rounded-md">
          <BoxCard01
            isCustomSymbol={false}
            toggleKPI={() => toggleKPI("Inventory")}
            title="Inventory"
            data={inventoryXeroData}
            savedFilterA={inventoryXeroFilters.firstFilter}
            savedFilterB={inventoryXeroFilters.comparedTo}
            sparkLine01Data={{}}
            sparkLine02Data={{}}
          />
        </div>
      </div>
    </div>
  );

  const dearKPIComponent = (
    <div class="mx-0 mt-5">
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
        <div class=" rounded-md">
          <BoxCard02
            isLoadingDearKPI={dearLoadingStatuses.dearUnitsShipped}
            isAdvancedDearFilters={
              openKPIAdvancedFilters[
              dearUnitsShipped?.results?.[0]?.kpi || "Units Shipped"
              ]
            }
            setIsAdvancedDearFilters={(value) =>
              toggleAdvancedFilters(
                dearUnitsShipped?.results?.[0]?.kpi || "Units Shipped",
                value
              )
            }
            isCustomSymbol={true}
            customSymbol={" "}
            toggleKPI={() =>
              toggleKPI(dearUnitsShipped?.results?.[0]?.kpi || "Units Shipped")
            }
            title={dearUnitsShipped?.results?.[0]?.kpi || "Units Shipped"}
            tooltip={
              dearUnitsShipped?.results?.[0]?.description ||
              "Quantity of units of products shipped in Cin7 Core"
            }
            data={
              dearUnitsShipped &&
                dearUnitsShipped.results &&
                dearUnitsShipped.results?.[0]?.data?.length > 0
                ? dearUnitsShipped.results?.[0]?.data[0]
                : null
            }
            savedFilterA={unitsShippedFilters.firstFilter}
            savedFilterB={unitsShippedFilters.comparedTo}
            sparkLine01Data={sparkLine01DearUnitsShippedData}
            sparkLine02Data={sparkLine02DearUnitsShippedData}
          />
        </div>
        <div class=" rounded-md">
          <BoxCard02
            isLoadingDearKPI={dearLoadingStatuses.dearOtherKPIs}
            isAdvancedDearFilters={
              openKPIAdvancedFilters[
              dearPercentageOrderShippedLate?.results?.[0]?.kpi ||
              "% of Orders Shipped Late"
              ]
            }
            setIsAdvancedDearFilters={(value) =>
              toggleAdvancedFilters(
                dearPercentageOrderShippedLate?.results?.[0]?.kpi ||
                "% of Orders Shipped Late",
                value
              )
            }
            customSymbol={"% "}
            isCustomSymbol={true}
            toggleKPI={() =>
              toggleKPI(
                dearPercentageOrderShippedLate?.results?.[0]?.kpi ||
                "% of Orders Shipped Late"
              )
            }
            title={
              dearPercentageOrderShippedLate?.results?.[0]?.kpi ||
              "% of Orders Shipped Late"
            }
            tooltip={
              dearPercentageOrderShippedLate?.results?.[0]?.description ||
              'The % of total sales orders which are shipped past the ship by date in Cin7 Core","data":[{"kpi":"% of orders shipped late'
            }
            data={
              dearPercentageOrderShippedLate &&
                dearPercentageOrderShippedLate.results &&
                dearPercentageOrderShippedLate.results?.[0]?.data?.length > 0
                ? dearPercentageOrderShippedLate.results?.[0]?.data[0]
                : null
            }
            savedFilterA={percentageOrderShippedLate.firstFilter}
            savedFilterB={percentageOrderShippedLate.comparedTo}
            sparkLine01Data={{}}
            sparkLine02Data={{}}
          />
        </div>
        <div class=" rounded-md">
          <BoxCard02
            isLoadingDearKPI={dearLoadingStatuses.dearOtherKPIs}
            isAdvancedDearFilters={
              openKPIAdvancedFilters[
              dearInventoryValue?.results?.[0]?.kpi || "Inventory Value"
              ]
            }
            setIsAdvancedDearFilters={(value) =>
              toggleAdvancedFilters(
                dearInventoryValue?.results?.[0]?.kpi || "Inventory Value",
                value
              )
            }
            toggleKPI={() =>
              toggleDrillDownModalSheet(
                dearInventoryValueDrilldown?.results?.[0]?.kpi ||
                "Inventory Value Drilldown",
                dearInventoryValueDrilldown?.results?.[0]?.description ||
                "Product level breakdown of inventory value in Cin7 Core",
                dearInventoryValueDrilldown?.results?.[0]?.data
              )
            }
            isCustomSymbol={false}
            title={dearInventoryValue?.results?.[0]?.kpi || "Inventory Value"}
            tooltip={
              dearInventoryValue?.results?.[0]?.description ||
              "Sum of stock on hand value of products in Cin7 Core"
            }
            data={
              dearInventoryValue?.results?.[0]?.data?.length > 0
                ? dearInventoryValue.results?.[0]?.data[0].total_stock_value
                : null
            }
            savedFilterA={inventoryValueFilters.firstFilter}
            savedFilterB={inventoryValueFilters.comparedTo}
          />
        </div>
        <div class=" rounded-md">
          <BoxCard02
            isLoadingDearKPI={dearLoadingStatuses.dearOtherKPIs}
            isAdvancedDearFilters={
              openKPIAdvancedFilters[
              dearOutOfStockRate?.results?.[0]?.kpi ||
              "Inventory Stockout Rate"
              ]
            }
            setIsAdvancedDearFilters={(value) =>
              toggleAdvancedFilters(
                dearOutOfStockRate?.results?.[0]?.kpi ||
                "Inventory Stockout Rate",
                value
              )
            }
            customSymbol={"% "}
            isCustomSymbol={true}
            title={
              dearOutOfStockRate?.results?.[0]?.kpi || "Inventory Stockout Rate"
            }
            tooltip={
              dearOutOfStockRate?.results?.[0]?.description ||
              "Percentage of products which are out of stock in Cin7 Core"
            }
            data={
              dearOutOfStockRate?.results?.[0]?.data?.length > 0
                ? dearOutOfStockRate.results?.[0]?.data[0]._field_1
                : null
            }
            savedFilterA={dearOutOfStockFilters.firstFilter}
            savedFilterB={dearOutOfStockFilters.comparedTo}
          />
        </div>
        <div class=" rounded-md">
          <BoxCard02
            isLoadingDearKPI={dearLoadingStatuses.dearOtherKPIs}
            isAdvancedDearFilters={
              openKPIAdvancedFilters[
              dearCostOfGoodsSold?.results?.[0]?.kpi || "Cost of Goods Sold"
              ]
            }
            setIsAdvancedDearFilters={(value) =>
              toggleAdvancedFilters(
                dearCostOfGoodsSold?.results?.[0]?.kpi || "Cost of Goods Sold",
                value
              )
            }
            isCustomSymbol={false}
            title={
              dearCostOfGoodsSold?.results?.[0]?.kpi || "Cost of Goods Sold"
            }
            tooltip={
              dearCostOfGoodsSold?.results?.[0]?.description ||
              "Sum of inventory movements in Cin7 Core"
            }
            data={
              dearCostOfGoodsSold &&
                dearCostOfGoodsSold.results &&
                dearCostOfGoodsSold.results?.[0]?.data?.length > 0
                ? dearCostOfGoodsSold.results?.[0]?.data[0]
                : null
            }
            savedFilterA={dearCostOfGoodsSoldFilters.firstFilter}
            savedFilterB={dearCostOfGoodsSoldFilters.comparedTo}
            sparkLine01Data={{}}
            sparkLine02Data={{}}
          />
        </div>
        <div class=" rounded-md">
          <BoxCard02
            isLoadingDearKPI={dearLoadingStatuses.dearOtherKPIs}
            isAdvancedDearFilters={openKPIAdvancedFilters[dearSampleShipped?.results?.[0]?.kpi || 'Number of Shipments of Samples to Customers']}
            setIsAdvancedDearFilters={(value) =>
              toggleAdvancedFilters(dearSampleShipped?.results?.[0]?.kpi || 'Number of Shipments of Samples to Customers', value)
            }
            isCustomSymbol={true}
            customSymbol={''}
            title={dearSampleShipped?.results?.[0]?.kpi || 'Number of Shipments of Samples to Customers'}
            tooltip={dearSampleShipped?.results?.[0]?.description || 'Number of customers who have been shipped a product with sample in the name in Cin7 Core'}
            data={
              dearSampleShipped &&
                dearSampleShipped.results &&
                dearSampleShipped.results?.[0]?.data?.length > 0
                ? dearSampleShipped.results?.[0]?.data[0]
                : null
            }
            savedFilterA={dearSampleShipped.firstFilter}
            savedFilterB={dearSampleShipped.comparedTo}
            sparkLine01Data={{}}
            sparkLine02Data={{}}
          />
        </div>


        <div class=" rounded-md">

          <BoxCard02
            isLoadingDearKPI={dearLoadingStatuses.dearOtherKPIs}
            isAdvancedDearFilters={openKPIAdvancedFilters[dearIncomingInventory?.results?.[0]?.kpi || 'Incoming Stock']}
            setIsAdvancedDearFilters={(value) =>
              toggleAdvancedFilters(dearIncomingInventory?.results?.[0]?.kpi || 'Incoming Stock', value)
            }
            toggleKPI={() =>
              toggleDrillDownModalSheet(
                dearIncomingInventoryDrilldown?.results?.[0]?.kpi ||
                "Incoming Stock Drilldown",
                dearIncomingInventoryDrilldown?.results?.[0]?.description ||
                "Product level view of the quantity of each product invoiced but not received on purchase orders in Cin7 Core",
                dearIncomingInventoryDrilldown?.results?.[0].data
              )
            }
            isCustomSymbol={false}
            title={dearIncomingInventory?.results?.[0]?.kpi || "Incoming Stock"}
            tooltip={
              dearIncomingInventory?.results?.[0]?.description ||
              "The sum of the cost (inventory value) of stock which has been invoiced but not received in purchase orders in Cin7 Core"
            }
            data={
              dearIncomingInventory?.results?.[0]?.data?.length > 0
                ? dearIncomingInventory.results?.[0]?.data[0].incoming_stock
                : null
            }
            savedFilterA={dearIncomingInventoryFilters.firstFilter}
            savedFilterB={dearIncomingInventoryFilters.comparedTo}
          />
        </div>
      </div>

      <div class="mx-0 mt-5 flex flex-row gap-2">
        <BoxTableCard01
        isDateFilters={true}
          title={
            dearNoSalesOrderByCarriers?.results?.[0]?.kpi ||
            "Top 10 Carriers By Sales Orders"
          }
          tooltip={
            dearNoSalesOrderByCarriers?.results?.[0]?.description ||
            "Number of sales orders by carrier in Cin7 Core"
          }
          isLoading={dearLoadingStatuses.dearOtherKPIs}
          tableData={dearNoSalesOrderByCarriers?.results?.[0]?.data}
        />
        <BoxTableCard01
        isDateFilters={true}
          title={
            dearProductsWithZeroSales?.results?.[0]?.kpi ||
            "Products With 0 Sales"
          }
          tooltip={
            dearProductsWithZeroSales?.results?.[0]?.description ||
            "List of products with 0 sales in Cin7 Core"
          }
          isLoading={dearLoadingStatuses.dearOtherKPIs}

          tableData={dearProductsWithZeroSales?.results?.[0]?.data} />
      </div>
    </div >
  );
  const displayKPIComponent = (item) => {
    switch (item) {
      case "XERO":
        return xeroKPIComponent;
      case "DEAR":
        return dearKPIComponent;
      default:
        return "Unknown";
    }
  };
  return (
    <div className="w-full h-full px-5 py-5 bg-light-white flex flex-col">
      <div className="bg-white flex-1 p-5 border-2 border-light-grey mt-5 justify-center items-start">
        <DashboardBanner title="Inventory" isGlobalFilterRequired={false} />
        <KPISheetModal
          toggle={toggleKPISheet}
          toggleModal={toggleKPI}
          kpiName={kpiSheetTitle}
        >
          <p className="font-bold text-lg">{kpiSheetTitle}</p>
          {kpiSheetTitle === "Number of Sales Credit Notes" && (
            <p>Your custom component here for "Number of Sales Credit Notes"</p>
          )}
          {kpiSheetTitle === "Units Shipped" && (
            <>
              <LineChartDear
                currencySymbol={""}
                companyData={unitsShippedLineGraphData}
                company={organisation}
                title={"Units Shipped by "}
              />
              <HorizontalBarChart
                currencySymbol={""}
                data={unitsShippedByBrandsChartData}
                title={"Top 10 Brands by Units Shipped"}
              />
              <HorizontalBarChart
                currencySymbol={""}
                data={unitsShippedByCategoryChartData}
                title={"Top 10 Categories by Units Shipped"}
              />
              <div className=" relative">
                <HorizontalBarChart
                  currencySymbol={""}
                  data={unitsShippedByCustomerChartData}
                  title={"Top 10 Customer Name by Units Shipped"}
                />
                <div className="absolute inset-0 bg-opacity-50 blur-sm bg-gray-100  backdrop-filter backdrop-blur-xm"></div>
                <div className="absolute inset-0 flex items-center justify-center text-gray-600">
                  <Link
                    to={{
                      pathname: `/details/${kpiSheetTitle}`,
                    }}
                    className="text-white text-sm bg-gray-500 rounded-sm py-2 px-6"
                  >
                    + Load More
                  </Link>
                </div>
              </div>
            </>
          )}
          {kpiSheetTitle === "Inventory" && (
            <LineChart
              currencySymbol={currencySymbol}
              companyData={inventoryLineGraphData}
              company={organisation}
              title={"Total Inventory of "}
            />
          )}
        </KPISheetModal>
        <KPIDrillDownModal
          tableData={drilldownTableData}
          drilldownTitle={drilldownTitle}
          drilldownTooltip={drilldownTooltip}
          toggle={toggleKPIDrillDownModal}
          toggleModal={toggleDrillDownModalSheet}
        />
        {organisation?.connected_apps &&
          organisation.connected_apps.map((item, index) => (
            <div key={index}>{displayKPIComponent(item)}</div>
          ))}
      </div>
    </div>
  );
}

export default DashboardInventoryPage;
