import { Send, Sparkles, User, UserRound, WandSparkles } from "lucide-react";
import React, { useEffect, useState, useRef } from "react";
import cin7 from "../../../../assets/logos/cin7_logo.png";
import xero from "../../../../assets/logos/xero_logo.png";
import qurk from "../../../../assets/logos/qurk_logo_circle.png";
import dearsystems from "../../../../assets/logos/dearsystems_logo.png";
import { useSelector } from "react-redux";
import SpinnerKPI from "../../../components/ui-elements/utilis/spinner-kpi";
import { motion } from "framer-motion";
import { AiFillCheckCircle } from "react-icons/ai";

const DotLoader = () => {
  return (
    <div className="flex gap-2 items-center">
      {[...Array(3)].map((_, i) => (
        <motion.span
          key={i}
          className="w-2 h-2 rounded-full bg-gray-600"
          animate={{ opacity: [0.3, 1, 0.3] }}
          transition={{
            duration: 1,
            repeat: Infinity,
            repeatType: "loop",
            delay: i * 0.5, // Stagger the animation of each dot
          }}
        />
      ))}
    </div>
  );
};
const apps = [
  {
    name: "Cin7",
    logo: cin7,
    category: "Accounting",
    link: "/connected-apps/apps/cin-7/",
  },
  {
    name: "Xero",
    logo: xero,
    category: "Accounting",
    link: "/connected-apps/apps/xero/",
  },
  {
    name: "Dear",
    name: "Dear",
    logo: cin7,
    category: "MRP/ERP",
    link: "/connected-apps/apps/dear/",
  },
];
function DashboardAIBetaPage(props) {
  const { user } = useSelector((state) => state.auth);
  const [message, setMessage] = useState("");
  const [chatMessages, setChatMessages] = useState([]);
  const [loading, setLoading] = useState(false); // State for loading spinner
  const socketRef = useRef(null);
  const { organisations, organisation, isLoading } = useSelector(
    (state) => state.organisation
  );

  // Initialize WebSocket connection on component mount
  useEffect(() => {
    const connectWebSocket = () => {
      socketRef.current = new WebSocket(
        "wss://qurk.uk/ws/threads/chat/robinai/"
      );

      socketRef.current.onopen = () => {
        console.log("WebSocket connection established");
      };

      socketRef.current.onmessage = (event) => {
        const incomingMessage = JSON.parse(event.data);
        console.log("Parsed incoming message:", incomingMessage);

        if (incomingMessage?.message?.summary?.summary) {
          const messageFromRobin = incomingMessage.message.summary.summary;
          setChatMessages((prevMessages) => [
            ...prevMessages,
            { text: messageFromRobin, sender: "bot" },
          ]);
          setLoading(false); // Hide loading spinner when message is received
        }
      };

      socketRef.current.onclose = () => {
        console.log("WebSocket connection closed. Reconnecting...");
        setTimeout(connectWebSocket, 1000); // Attempt to reconnect after 1 second
      };

      socketRef.current.onerror = (error) => {
        console.error("WebSocket error observed:", error);
      };
    };

    connectWebSocket(); // Initialize the WebSocket connection

    return () => {
      socketRef.current.close(); // Cleanup on unmount
    };
  }, []);

  // Send message to WebSocket server
  const sendMessage = () => {
    if (message.trim() !== "") {
      const messageData = {
        user: user?.data?.name, // if needed by the server
        message: message,
      };

      // Send JSON formatted message
      socketRef.current.send(JSON.stringify(messageData));

      // Update the chat with user message
      setChatMessages((prevMessages) => [
        ...prevMessages,
        { text: message, sender: "user" },
      ]);
      setMessage(""); // Clear input field after sending message
      setLoading(true); // Show loading spinner until response is received
    }
  };

  return (
    <div className="w-full h-screen bg-light-white flex flex-col">
      <header className="w-full bg-white shadow-sm pt-20 pb-4">
        <div className="max-w-7xl px-4 sm:px-6 lg:px-8">
          <p className="text-3xl flex gap-2 items-center font-extralight">
            <Sparkles />
            AI Assistant
          </p>
        </div>
      </header>

      <div className="bg-transparent flex-1 p-7 m-5 justify-center items-start">
        <div className="min-h-full bg-white p-7 flex flex-col justify-between items-start w-full rounded-md">
          <main className="w-full flex-grow p-2 flex flex-col items-start gap-10">
            {/* Greeting Section */}
            <div className="w-full">
              <h2 className="text-[48px] font-bold bg-gradient-to-r from-purple via-[#764bc9] to-[#9a83c9] inline-block text-transparent bg-clip-text">
                Hello, {user?.data?.name}
              </h2>
              <p className="text-[60px] text-gray-300">
                What KPI's are you looking for?
              </p>
            </div>

            <div className="w-1/2 flex flex-col items-start">
              <p className="text-md text-gray-500 mb-4">
                Your Connected Apps ({organisation?.connected_apps?.length}):
              </p>
              <div className="flex gap-4 w-full item">
                {organisation?.connected_apps?.length > 0 ? (
                  <>
                    {apps
                      .filter((app) =>
                        organisation?.connected_apps.some(
                          (connectedApp) =>
                            connectedApp.toLowerCase() ===
                            app.name.toLowerCase()
                        )
                      )
                      ?.map((app) => (
                        <div>
                          <div
                            className={`  p-4 rounded-lg gap-4 flex flex-col items-start justify-between ${
                              app?.name === "Dear"
                                ? "bg-light-purple/50 "
                                : "bg-slate-100"
                            }`}
                          >
                            <img
                              src={app.logo}
                              alt=""
                              className={` bg-white rounded-full w-14 h-14 ${
                                app?.name === "Dear" ? "p-2 " : "p-0"
                              }`}
                            />
                            <div>
                              <p className="text-md font-semibold text-gray-700">
                                {app?.name === "Cin7"
                                  ? "Cin7 Omni"
                                  : app?.name === "Dear"
                                  ? "Cin7 Core"
                                  : app?.name}
                              </p>
                              <p className="text-[12px] text-gray-500">
                                Updated 3 hours 46 mins ago
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                  </>
                ) : (
                  <div className="flex items-center  justify-center w-full h-auto p-2">
                    <p>No Apps Connected</p>
                  </div>
                )}
              </div>
            </div>
            {organisation?.slug === "ecomposite-products" ? (
              <>
                <div className="w-full">
                  <div className="flex flex-col gap-4 mb-4">
                    {chatMessages.map((msg, index) => (
                      <div
                        key={index}
                        className={`py-2 px-5 rounded-full flex items-center gap-2 ${
                          msg.sender === "user"
                            ? "self-end text-gray-800"
                            : "self-start text-gray-600"
                        }`}
                      >
                        {msg.sender !== "user" && (
                          <img
                            src={qurk}
                            className="w-10 h-10 image-cover rounded-full"
                          />
                        )}
                        <p
                          className={`py-2 px-5 rounded-full flex items-center justify-center gap-2 ${
                            msg.sender === "user"
                              ? "bg-warning/50 self-end text-dark-purple"
                              : "bg-gray-100 self-start text-gray-600"
                          }`}
                        >
                          {msg.text}
                        </p>
                        {msg.sender === "user" && (
                          <div className="border  border-gray-700 p-1.5 rounded-full">
                            <UserRound size={24} />
                          </div>
                        )}
                      </div>
                    ))}
                    {loading && (
                      <div className=" flex items-center   gap-2">
                        <img
                          src={qurk}
                          className="w-10 h-10 image-cover rounded-full"
                        />
                        <div className="py-4 pl-6 pr-8  flex items-center justify-center rounded-full bg-gray-100 self-start">
                          <DotLoader />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* Input Section */}
                <div className="w-full mt-auto">
                  <div className="w-full relative flex items-center">
                    <div className="w-full">
                      <input
                        type="text"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Enter what KPIs you are looking for"
                        className="w-full py-3 px-4 rounded-full border border-gray-300 bg-light-white focus:outline-none focus:ring-1 focus:ring-purple"
                      />
                    </div>
                    <button
                      className="ml-2 p-2 absolute right-2 bg-purple rounded-full hover:bg-purple-700"
                      onClick={sendMessage}
                    >
                      <Send color="white" />
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <p className="text-lg font-bold text-dark-purple">
                  Stay Tuned!
                </p>
              </>
            )}
            {/* Chat Section */}
          </main>
        </div>
      </div>
    </div>
  );
}

export default DashboardAIBetaPage;
