import React, { useEffect, useRef, useState } from "react";
import { BiCommentDetail } from "react-icons/bi";
import { BsArrowDownCircleFill, BsFillArrowUpCircleFill } from "react-icons/bs";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import SpinnerKPI from "../utilis/spinner-kpi";
import SimpleSparkline from "../../graphs/recharts/spark-line";
import dear from "../../../../assets/logos/cin7-core-teal-1.png";
import Tippy from "@tippyjs/react";
import { IoIosInformationCircle } from "react-icons/io";
import countryToSymbolMap from "../../../../constant/country-code-currency.json";
import { BsFillFilterSquareFill } from "react-icons/bs";
import MultiSelect from "../utilis/multi-select";
import {
  getDearProductSalesKPIFilteredDataCategory,
  getDearGrossProfitMarginKPIFilteredDataCategory,
  getDearInventoryValueKPIFilteredDataCategory,
} from "../../../../redux-features/slices/kpi/kpiSlice";
import { Badge } from "../../../../shadcn-components/ui/badge";
import { Toggle } from "../../../../shadcn-components/ui/toggle";
const BoxCard02WithFilter = (props) => {
  const {
    title,
    customSymbol,
    isCustomSymbol,
    tooltip,
    data,
    products,
    toggleKPI,
    isAdvancedDearFilters,
    setIsAdvancedDearFilters,
    categoryFilter,
    isLoading
  } = props;
  const dispatch = useDispatch();
  const [brandFilterSelected, setBrandFilterSelected] = useState(null);
  const [categoryFilterSelected, setCategoryFilterSelected] = useState(null);
  const [contactFilterSelected, setContactFilterSelected] = useState(null);
  const [customerTagsFilterSelected, setCustomerTagsFilterSelected] =
    useState(null);
  const [productFilterSelected, setProductFilterSelected] = useState(null);
  const [productTagsFilterSelected, setProductTagsFilterSelected] =
    useState(null);
  const [productTypeFilterSelected, setProductTypeFilterSelected] =
    useState(null);
  const [locationFilterSelected, setLocationFilterSelected] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const { organisation } = useSelector((state) => state.organisation);
  const {
    dearBrandFilters,
    dearContactFilters,
    dearCustomerTagsFilters,
    dearProductTagsFilters,
    dearProductTypeFilters,
    dearLocationFilters,
  } = useSelector((state) => state.kpi);

  const token = user?.data?.api_token;
  useEffect(() => {
    setSelectedValue("Previous Period");
    setFilteredText(formatDate(lastMonthStartDate));
    setSelectedCurrentPeriodValue(data?.last_month);
    setSelectedPastPeriodValue(data?.last_month_last_month);
    setSelectedChangePeriodValue(data?.last_month_last_month_change);
    handleGoButtonClick();
  }, [data]);

  useEffect(() => {
    const fetchData = async () => {
      const selectedBrands =
        brandFilterSelected?.map((selected) => selected.value) || [];
      const selectedCategories =
        categoryFilterSelected?.map((selected) => selected.value) || [];
      const selectedContacts =
        contactFilterSelected?.map((selected) => selected.value) || [];
      const selectedProducts =
        productFilterSelected?.map((selected) => selected.value) || [];
      const selectedProductTypes =
        productTypeFilterSelected?.map((selected) => selected.value) || [];
      const selectedProductTags =
        productTagsFilterSelected?.map((selected) => selected.value) || [];
      const selectedCustomerTags =
        customerTagsFilterSelected?.map((selected) => selected.value) || [];
      const dataset_id = organisation?.dear_ds;
      const params = {
        brand: selectedBrands.join(", "),
        contact: selectedContacts.join(", "),
        category: "",
        product: products.join(", "),
        productType: selectedProductTypes.join(", "),
        productTags: selectedProductTags.join(", "),
        customerTags: selectedCustomerTags.join(", "),
      };

      // if (title === "Product Sales") {
      //   dispatch(
      //     getDearProductSalesKPIFilteredDataCategory({
      //       dataset_id,
      //       token,
      //       params,
      //       categoryFilter,
      //     })
      //   );
      // }

      // if (title === "Gross Profit Margin") {
      //   await dispatch(
      //     getDearGrossProfitMarginKPIFilteredDataCategory({
      //       dataset_id,
      //       token,
      //       params,
      //       categoryFilter,

      //     })
      //   );
      // }
      // if (title === "Inventory Value") {
      //   await dispatch(
      //     getDearInventoryValueKPIFilteredDataCategory({
      //       dataset_id,
      //       token,
      //       params,
      //       categoryFilter,

      //     })
      //   );
      // }
    };

    fetchData();
  }, [
    organisation,
  ]);

  // Filters
  const [filterA, setFilterA] = useState("Last Month");
  const [filterB, setFilterB] = useState("Previous Period");
  const [filteredText, setFilteredText] = useState("Select Filters");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll((prevShowAll) => !prevShowAll);
  };
  const handleFilterChange = (filter, value) => {
    if (filter === "filterA") {
      setFilterA(value);
    } else if (filter === "filterB") {
      setFilterB(value);
    }
  };
  // Date
  function formatDate(date) {
    const options = { month: "short", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }

  const handleAdvanceDearFilters = () => {
    setIsAdvancedDearFilters(!isAdvancedDearFilters);
  };

  // Get the current date
  const currentDate = new Date();

  // Get the current day of the week (0 is Sunday, 1 is Monday, ..., 6 is Saturday)
  const currentDayOfWeek = currentDate.getDay();

  // Calculate the difference in days between the current day and Sunday (assuming Sunday is the first day of the week)
  const daysUntilSunday = currentDayOfWeek === 0 ? 0 : 7 - currentDayOfWeek;

  // Calculate the start date of the current week (Sunday)
  const thisWeekStartDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - daysUntilSunday
  );

  // Calculate the end date of the current week (Saturday)
  const thisWeekEndDate = new Date(
    thisWeekStartDate.getFullYear(),
    thisWeekStartDate.getMonth(),
    thisWeekStartDate.getDate() + 6
  );

  // Calculate the start date of the previous week (7 days before the start of the current week)
  const lastWeekStartDate = new Date(
    thisWeekStartDate.getFullYear(),
    thisWeekStartDate.getMonth(),
    thisWeekStartDate.getDate() - 7
  );

  // Calculate the end date of the previous week (6 days before the start of the current week)
  const lastWeekEndDate = new Date(
    thisWeekStartDate.getFullYear(),
    thisWeekStartDate.getMonth(),
    thisWeekStartDate.getDate() - 1
  );

  // Calculate dates for this month
  const thisMonthStartDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const thisMonthEndDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );

  // Calculate dates for last month
  const lastMonthStartDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 1,
    1
  );
  const lastMonthEndDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    0
  );

  // Calculate dates for this quarter
  const currentQuarter = Math.floor(currentDate.getMonth() / 3);
  const thisQuarterStartDate = new Date(
    currentDate.getFullYear(),
    currentQuarter * 3,
    1
  );
  const thisQuarterEndDate = new Date(
    currentDate.getFullYear(),
    (currentQuarter + 1) * 3,
    0
  );

  // Calculate dates for last quarter
  const lastQuarterStartDate = new Date(
    currentDate.getFullYear(),
    (currentQuarter - 1) * 3,
    1
  );
  const lastQuarterEndDate = new Date(
    currentDate.getFullYear(),
    currentQuarter * 3,
    0
  );
  // Calculate start date of the current month 12 months ago
  const twelveMonthsAgoStartDate = new Date(
    currentDate.getFullYear() - 1,
    currentDate.getMonth(),
    currentDate.getDate()
  );

  // Calculate end date of the current month
  const currentMonthEndDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );

  // Calculate end date of the current month 12 months ago
  const twelveMonthsAgoEndDate = new Date(
    twelveMonthsAgoStartDate.getFullYear(),
    twelveMonthsAgoStartDate.getMonth() + 1,
    0
  );

  // Format dates
  const formattedTwelveMonthsAgoStartDate = formatDate(
    twelveMonthsAgoStartDate
  );
  const formattedCurrentMonthEndDate = formatDate(currentMonthEndDate);
  const formattedTwelveMonthsAgoEndDate = formatDate(twelveMonthsAgoEndDate);

  // Calculate dates for Year to Date
  const thisYearStartDate = new Date(currentDate.getFullYear(), 0, 1);
  const thisYearEndDate = new Date(currentDate.getFullYear(), 11, 31);

  // Calculate dates for last year
  const lastYearStartDate = new Date(currentDate.getFullYear() - 1, 0, 1);
  const lastYearEndDate = new Date(currentDate.getFullYear() - 1, 11, 31);

  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedCurrentPeriodValue, setSelectedCurrentPeriodValue] = useState(
    data?.this_month
  );
  const [selectedPastPeriodValue, setSelectedPastPeriodValue] = useState(
    data?.last_month
  );
  const [selectedChangePeriodValue, setSelectedChangePeriodValue] = useState(
    data?.this_month_last_month_change
  );

  const [isDropdownFilterOpen, setIsDropdownFilterOpen] = useState(false);

  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!dropdownRef?.current?.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [dropdownRef]);

  const countryCode =
    organisation && organisation.country_alpha3
      ? organisation.country_alpha3.replace(/"/g, "")
      : null; // Remove double quotes
  const currencySymbol = countryCode ? countryToSymbolMap[countryCode] : null;

  const handleGoButtonClick = () => {
    let text = "";
    let selectedValue = "";
    let selectedCurrentPeriodValue = data?.this_month;
    let selectedPastPeriodValue = data?.last_month;
    let selectedChangePeriodValue = data?.this_month_last_month_change;
    switch (`${filterA}_${filterB}`) {
      case "This Week_Previous Period":
        text = formatDate(thisWeekStartDate);
        selectedValue = "Previous Period";
        selectedCurrentPeriodValue = data?.this_week;
        selectedPastPeriodValue = data?.last_week;
        selectedChangePeriodValue = data?.this_week_last_week_change;
        break;
      case "Last Week_Previous Period":
        text = formatDate(lastWeekStartDate);
        selectedValue = "Previous Period";
        selectedCurrentPeriodValue = data?.last_week;
        selectedPastPeriodValue = data?.last_week_last_week;
        selectedChangePeriodValue = data?.last_week_last_week_change;
        break;

      case "This Month_Previous Period":
        text = formatDate(thisMonthStartDate);
        selectedValue = "Previous Period";
        selectedCurrentPeriodValue = data?.this_month;
        selectedPastPeriodValue = data?.last_month;
        selectedChangePeriodValue = data?.this_month_last_month_change;
        break;
      case "Last Month_Previous Period":
        text = formatDate(lastMonthStartDate);
        selectedValue = "Previous Period";
        selectedCurrentPeriodValue = data?.last_month;
        selectedPastPeriodValue = data?.last_month_last_month;
        selectedChangePeriodValue = data?.last_month_last_month_change;
        break;
      case "This Quarter_Previous Period":
        text =
          formatDate(thisQuarterStartDate) +
          "-" +
          formatDate(thisQuarterEndDate);
        selectedValue = "Previous Period";
        selectedCurrentPeriodValue = data?.this_quarter;
        selectedPastPeriodValue = data?.last_quarter;
        selectedChangePeriodValue = data?.this_quarter_last_quarter_change;
        break;
      case "Last Quarter_Previous Period":
        text =
          formatDate(lastQuarterStartDate) +
          "-" +
          formatDate(lastQuarterEndDate);
        selectedValue = "Previous Period";
        selectedCurrentPeriodValue = data?.last_quarter;
        selectedPastPeriodValue = data?.last_quarter_last_quarter;
        selectedChangePeriodValue = data?.last_quarter_last_quarter_change;
        break;
      case "Year to Date_Previous Period":
        text =
          formatDate(thisYearStartDate) + "-" + formatDate(thisYearEndDate);
        selectedValue = "Previous Period";
        selectedCurrentPeriodValue = data?.this_year;
        selectedPastPeriodValue = data?.last_year;
        selectedChangePeriodValue = data?.this_year_last_year_change;
        break;
      case "Last 365 Days_Previous Period":
        text =
          formatDate(lastQuarterStartDate) +
          "-" +
          formatDate(lastQuarterEndDate);
        selectedValue = "Previous Period";
        selectedCurrentPeriodValue = data?.last_365_days;
        selectedPastPeriodValue = data?.last_365_days_previous;
        selectedChangePeriodValue = data?.last_365_days_previous_change;
        break;
      case "Last 365 Days_Previous Year":
        text =
          formatDate(lastQuarterStartDate) +
          "-" +
          formatDate(lastQuarterEndDate);
        selectedValue = "Previous Period";
        selectedCurrentPeriodValue = data?.last_365_days;
        selectedPastPeriodValue = data?.last_365_days_previous;
        selectedChangePeriodValue = data?.last_365_days_previous_change;
        break;

      case "Last Year_Previous Period":
        selectedValue = "Previous Period";
        text =
          formatDate(lastYearStartDate) + "-" + formatDate(lastYearEndDate);
        selectedCurrentPeriodValue = data?.last_year;
        selectedPastPeriodValue = data?.last_year_last_year;
        selectedChangePeriodValue = data?.last_year_last_year_change;
        break;
      case "This Week_Previous Year":
        text =
          formatDate(thisWeekStartDate) + "-" + formatDate(thisWeekEndDate);
        selectedValue = "Previous Year";
        selectedCurrentPeriodValue = data?.this_week;
        selectedPastPeriodValue = data?.this_week_last_year;
        selectedChangePeriodValue = data?.this_week_last_year_change;
        break;
      case "Last Week_Previous Year":
        text =
          formatDate(lastWeekStartDate) + "-" + formatDate(lastWeekEndDate);
        selectedValue = "Previous Year";
        selectedCurrentPeriodValue = data?.last_week;
        selectedPastPeriodValue = data?.last_week_last_year;
        selectedChangePeriodValue = data?.last_week_last_year_change;
        break;
      case "This Month_Previous Year":
        setFilteredText(
          formatDate(thisMonthStartDate) + " - " + formatDate(thisMonthEndDate)
        );
        selectedValue = "Previous Year";
        selectedCurrentPeriodValue = data?.this_month;
        selectedPastPeriodValue = data?.this_month_last_year;
        selectedChangePeriodValue = data?.this_month_last_year_change;
        break;
      case "Last Month_Previous Year":
        text =
          formatDate(lastMonthStartDate) + " - " + formatDate(lastMonthEndDate);
        selectedValue = "Previous Year";
        selectedCurrentPeriodValue = data?.last_month;
        selectedPastPeriodValue = data?.last_month_last_year;
        selectedChangePeriodValue = data?.last_month_last_year_change;
        break;
      case "This Quarter_Previous Year":
        text =
          formatDate(thisQuarterStartDate) +
          "-" +
          formatDate(thisQuarterEndDate);
        selectedValue = "Previous Year";
        selectedCurrentPeriodValue = data?.this_quarter;
        selectedPastPeriodValue = data?.this_quarter_last_year;
        selectedChangePeriodValue = data?.this_quarter_last_year_change;
        break;
      case "Last Quarter_Previous Year":
        text =
          formatDate(lastQuarterStartDate) +
          "-" +
          formatDate(lastQuarterEndDate);
        selectedValue = "Previous Year";
        selectedCurrentPeriodValue = data?.last_quarter;
        selectedPastPeriodValue = data?.last_quarter_last_year;
        selectedChangePeriodValue = data?.last_quarter_last_year_change;
        break;
      case "Year to Date_Previous Year":
        text =
          formatDate(thisYearStartDate) + "-" + formatDate(thisYearEndDate);
        selectedValue = "Previous Year";
        selectedCurrentPeriodValue = data?.this_year;
        selectedPastPeriodValue = data?.last_year;
        selectedChangePeriodValue = data?.this_year_last_year_change;
        break;
      case "Last Year_Previous Year":
        // text = `Filtered text based on ${filterA} and ${filterB}`;
        text =
          formatDate(lastYearStartDate) + "-" + formatDate(lastYearEndDate);
        selectedValue = "Previous Year";
        selectedCurrentPeriodValue = data?.last_year;
        selectedPastPeriodValue = data?.last_year_last_year;
        selectedChangePeriodValue = data?.last_year_last_year_change;
        break;
      default:
        text =
          formatDate(thisMonthStartDate) + " - " + formatDate(thisMonthEndDate);
        selectedValue = "Previous Period";
        selectedCurrentPeriodValue = data?.this_month;
        selectedPastPeriodValue = data?.last_month;
        selectedChangePeriodValue = data?.this_month_last_month_change;
    }
    setFilteredText(text);
    setSelectedValue(selectedValue);
    setSelectedCurrentPeriodValue(selectedCurrentPeriodValue);
    setSelectedPastPeriodValue(selectedPastPeriodValue);
    setSelectedChangePeriodValue(selectedChangePeriodValue);
    setIsDropdownOpen(false);
  };
  const filterOptions = [
    { label: "This Week", value: "This Week" },
    { label: "Last Week", value: "Last Week" },
    { label: "This Month", value: "This Month" },
    { label: "Last Month", value: "Last Month" },
    { label: "This Quarter", value: "This Quarter" },
    { label: "Last Quarter", value: "Last Quarter" },
    { label: "Year to Date", value: "Year to Date" },
    { label: "Last 365 Days", value: "Last 365 Days" },
    // { label: "Last Year", value: "Last Year" },
  ];
  const brandFilterOptions = Array.isArray(dearBrandFilters)
    ? dearBrandFilters.map((filter) => ({
      label: filter?.brand
        ? filter.brand
          .toLowerCase()
          .replace(/\b\w/g, (char) => char.toUpperCase())
        : "",
      value: filter?.brand || "",
    }))
    : [];

  const contactFilterOptions = Array.isArray(dearContactFilters)
    ? dearContactFilters.map((filter) => ({
      label: filter?.contact_name
        ? filter.contact_name
          .toLowerCase()
          .replace(/\b\w/g, (char) => char.toUpperCase())
        : "",
      value: filter?.contact_name || "",
    }))
    : [];

  const customerTagsFilterOptions = Array.isArray(dearCustomerTagsFilters)
    ? dearCustomerTagsFilters.map((filter) => ({
      label: filter?.customer_tag
        ? filter.customer_tag
          .toLowerCase()
          .replace(/\b\w/g, (char) => char.toUpperCase())
        : "",
      value: filter?.customer_tag || "",
    }))
    : [];


  const productTagsFilterOptions = Array.isArray(dearProductTagsFilters)
    ? dearProductTagsFilters.map((filter) => ({
      label: filter?.product_tag
        ? filter.product_tag
          .toLowerCase()
          .replace(/\b\w/g, (char) => char.toUpperCase())
        : "",
      value: filter?.product_tag || "",
    }))
    : [];

  const productTypeFilterOptions = Array.isArray(dearProductTypeFilters)
    ? dearProductTypeFilters.map((filter) => ({
      label: filter?.product_type
        ? filter.product_type
          .toLowerCase()
          .replace(/\b\w/g, (char) => char.toUpperCase())
        : "",
      value: filter?.product_type || "",
    }))
    : [];
  const locationFilterOptions = Array.isArray(dearLocationFilters)
    ? dearLocationFilters.map((filter) => ({
      label: filter?.location
        ? filter.location
          .toLowerCase()
          .replace(/\b\w/g, (char) => char.toUpperCase())
        : "",
      value: filter?.location || "",
    }))
    : [];

  const handleContactChange = (selected) => {
    setContactFilterSelected(selected);
  };

  const handleCustomerTagsChange = (selected) => {
    setCustomerTagsFilterSelected(selected);
  };
  const handleBrandChange = (selected) => {
    setBrandFilterSelected(selected);
  };
  const handleProductChange = (selected) => {
    setProductFilterSelected(selected);
  };

  const handleProductTagsChange = (selected) => {
    setProductTagsFilterSelected(selected);
  };

  const handleProductTypeChange = (selected) => {
    setProductTypeFilterSelected(selected);
  };

  const handleCategoryChange = (selected) => {
    setCategoryFilterSelected(selected);
  };
  const handleLocationChange = (selected) => {
    setLocationFilterSelected(selected);
  };

  const saveSelectedFilters = async () => {
    const selectedBrands =
      brandFilterSelected?.map((selected) => selected.value) || [];
    const selectedCategories =
      categoryFilterSelected?.map((selected) => selected.value) || [];
    const selectedContacts =
      contactFilterSelected?.map((selected) => selected.value) || [];
    const selectedProducts =
      productFilterSelected?.map((selected) => selected.value) || [];
    const selectedProductTypes =
      productTypeFilterSelected?.map((selected) => selected.value) || [];
    const selectedProductTags =
      productTagsFilterSelected?.map((selected) => selected.value) || [];
    const selectedCustomerTags =
      customerTagsFilterSelected?.map((selected) => selected.value) || [];
    const selectedLocations =
      locationFilterSelected?.map((selected) => selected.value) || [];
    const dataset_id = organisation?.dear_ds;
    const params = {
      brand: selectedBrands.join(", "),
      category: "",
      contact: selectedContacts.join(", "),
      product: products.join(", "),
      productType: selectedProductTypes.join(", "),
      productTags: selectedProductTags.join(", "),
      customerTags: selectedCustomerTags.join(", "),
      location: selectedLocations.join(", "),
    };

    if (title === "Product Sales") {
      await dispatch(
        getDearProductSalesKPIFilteredDataCategory({
          dataset_id,
          token,
          params,
        })
      );
    }
    if (title === "Gross Profit Margin") {
      await dispatch(
        getDearGrossProfitMarginKPIFilteredDataCategory({
          dataset_id,
          token,
          params,
        })
      );
    }
    if (title === "Inventory Value") {
      await dispatch(
        getDearInventoryValueKPIFilteredDataCategory({
          dataset_id,
          token,
          params,
        })
      );
    }
    await handleAdvanceDearFilters();
  };
  const handleAdvanceDearFiltersClose = () => {
    handleAdvanceDearFilters();
  };
  const remainingCount = products.length - 2;
  return (
    <div className="flex-shrink-0 w-full">
      <div className="flex flex-col min-h-[280px]">
        <div className="flex-grow h-full border-[0.5px] rounded-lg  bg-white shadow-sm border-zinc-200">
          <div className="px-5 py-3 w-full flex flex-col items-start gap-2 justify-between  h-full relative">
            <div className="flex w-full flex-col gap-2 h-full justify-between">
              <div className="flex gap-2 lg:flex-row flex-col w-full lg:items-center items-start justify-between">
                <p className="flex flex-row gap-2 capitalize justify-center items-center text-xs text-dark-purple/60 font-semibold">
                  <div className="rounded-full flex items-center justify-center  h-9 w-9 bg-slate-200">
                    <Tippy
                      theme="custom-white"
                      size="small"
                      placement="top"
                      content="Dear"
                    >
                      <img
                        className="rounded-full object-contain h-[26px] w-[26px]"
                        src={dear}
                      />
                    </Tippy>
                  </div>
                  {title.charAt(0).toUpperCase() + title.slice(1).toLowerCase()}
                  <Tippy
                    theme="custom-white"
                    size="small"
                    placement="top"
                    content={tooltip}
                  >
                    <div>
                      <IoIosInformationCircle
                        className="  text-grey"
                        size={14}
                      />
                    </div>
                  </Tippy>
                </p>
                <div className="flex flex-row justify-center items-center gap-2">
                  {/* <Dropdown></Dropdown> */}
                  {title === "Inventory Value" ? (
                    <></>
                  ) : (
                    <div className="relative text-left">
                      <div>
                        <span className="rounded-md z-10 shadow-sm">
                          <button
                            type="button"
                            className="inline-flex capitalize   justify-center text-[10px]  w-full rounded-md border  border-gray-300 bg-white px-3 py-1  font-medium text-gray-400 hover:bg-gray-50 focus:outline-none  "
                            id="options-menu"
                            aria-haspopup="true"
                            aria-expanded={isDropdownOpen ? "true" : "false"}
                            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                          >
                            {filterA
                              .replace("_", " ")
                              .replace(/^\w/, (c) => c.toUpperCase())}{" "}
                            ({filteredText})
                            <svg
                              className="-mr-1 ml-2 h-4 w-4"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 12a1 1 0 01-.7-.29l-4-4a1 1 0 111.41-1.42L10 9.59l3.29-3.3a1 1 0 111.42 1.42l-4 4a1 1 0 01-.71.29z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </button>
                        </span>
                      </div>
                      <div ref={dropdownRef}>
                        {isDropdownOpen && (
                          <div
                            ref={dropdownRef}
                            className="origin-top-right z-20 absolute text-gray-500 text-xs  w-[155px] rounded-md shadow-md bg-white ring-1 ring-black ring-opacity-5"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="options-menu"
                          >
                            <div
                              className="flex gap-2 text-xs px-2 py-4 flex-col "
                              role="none"
                            >
                              <p className="font-semibold text-xs text-grey">
                                Current Period
                              </p>
                              {filterOptions.map((option) => (
                                <label
                                  key={option.value}
                                  className="inline-flex items-center"
                                >
                                  <input
                                    type="radio"
                                    className="form-radio text-purple h-4 w-4"
                                    name="filterA"
                                    value={option.value}
                                    checked={filterA === option.value}
                                    onChange={() =>
                                      handleFilterChange(
                                        "filterA",
                                        option.value
                                      )
                                    }
                                  />
                                  <span className="ml-2">{option.label}</span>
                                </label>
                              ))}
                              <hr className="my-1" />
                              <p className="font-semibold text-xs text-grey">
                                Compare with
                              </p>
                              <label className="inline-flex items-center">
                                <input
                                  type="radio"
                                  className="form-radio text-purple h-4 w-4"
                                  name="filterB"
                                  value="Previous Period"
                                  checked={filterB === "Previous Period"}
                                  onChange={() =>
                                    handleFilterChange(
                                      "filterB",
                                      "Previous Period"
                                    )
                                  }
                                />
                                <span className="ml-2">Previous Period</span>
                              </label>
                              <label className="inline-flex items-center">
                                <input
                                  type="radio"
                                  className="form-radio text-purple h-4 w-4"
                                  name="filterB"
                                  value="Previous Year"
                                  checked={filterB === "Previous Year"}
                                  onChange={() =>
                                    handleFilterChange(
                                      "filterB",
                                      "Previous Year"
                                    )
                                  }
                                />
                                <span className="ml-2">Previous Year</span>
                              </label>
                            </div>
                            <div className="flex justify-end px-2 py-2">
                              <button
                                type="button"
                                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm py-1 bg-purple text-base font-medium text-white hover:bg-purple/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                                onClick={handleGoButtonClick}
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div
                    className="cursor-pointer"
                    onClick={handleAdvanceDearFilters}
                  >
                    <BsFillFilterSquareFill className="text-purple" size={22} />
                  </div>
                </div>
              </div>
            </div>
            {isAdvancedDearFilters ? (
              <div className="w-full mt-2 ">
                <div className="w-full flex flex-col gap-2">
                  <div className="flex items-center flex-row">
                    <p className="w-1/4 text-[10px]">Brands: </p>
                    <MultiSelect
                      color="#fdba74"
                      className="w-full"
                      key="brand_id"
                      options={brandFilterOptions}
                      onChange={handleBrandChange}
                      value={brandFilterSelected}
                      isSelectAll={true}
                      menuPlacement={"bottom"}
                    />
                  </div>

                  <div className="flex items-center flex-row">
                    <p className="w-1/4 text-[10px]">Product Tag: </p>
                    <MultiSelect
                      className="w-full"
                      key="product_tags_id"
                      // color="#69b6ff"
                      color="#7dd3fc"
                      options={productTagsFilterOptions}
                      onChange={handleProductTagsChange}
                      value={productTagsFilterSelected}
                      isSelectAll={true}
                      menuPlacement={"bottom"}
                    />
                  </div>
                  <div className="flex items-center flex-row">
                    <p className="w-1/4 text-[10px]">Customer: </p>
                    <MultiSelect
                      className="w-full"
                      key="customer_tag_id"
                      color="#9ae6c7"
                      options={contactFilterOptions}
                      onChange={handleContactChange}
                      value={contactFilterSelected}
                      isSelectAll={true}
                      menuPlacement={"bottom"}
                    />
                  </div>
                  <div className="flex items-center flex-row">
                    <p className="w-1/4 text-[10px]">Customer Tag: </p>
                    <MultiSelect
                      className="w-full"
                      key="customer_tag_id"
                      color="#6ee7b7"
                      options={customerTagsFilterOptions}
                      onChange={handleCustomerTagsChange}
                      value={customerTagsFilterSelected}
                      isSelectAll={true}
                      menuPlacement={"bottom"}
                    />
                  </div>
                  <div className="flex items-center flex-row">
                    <p className="w-1/4 text-[10px]">Type: </p>
                    <MultiSelect
                      className="w-full"
                      key="product_type_id"
                      color="#d8b4fe"
                      options={productTypeFilterOptions}
                      onChange={handleProductTypeChange}
                      value={productTypeFilterSelected}
                      isSelectAll={true}
                      menuPlacement={"bottom"}
                    />
                  </div>
                  <div className="flex items-center mt-3 flex-row gap-2 ">
                    <button
                      onClick={saveSelectedFilters}
                      className="w-full inline-flex justify-center rounded-md border border-transparent text-xs shadow-sm py-2 bg-purple  font-medium text-white hover:bg-purple/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Save Filters
                    </button>
                    <button
                      type="button"
                      onClick={handleAdvanceDearFiltersClose}
                      className="w-full inline-flex justify-center rounded-md border border-slate-200 text-xs shadow-sm py-2  font-medium text-grey hover:bg-dark-purple hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 "
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <>

                <div className="w-full">
                  {title === "Inventory Value" ? (
                    (
                      <>
                        {isLoading ? (
                          <SpinnerKPI />
                        ) :
                          (
                            <p
                              className="text-[32px] text-zinc-700 font-bold hover:underline"
                            >
                              {data ? (
                                <>
                                  £ {" "}
                                  {data?.toLocaleString(undefined, {
                                    maximumFractionDigits: 0,
                                  }).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                </>
                              ) : (
                                <p className="text-[10px] font-light text-grey italic">
                                  No data found, please refresh or try later
                                </p>
                              )}
                            </p>
                          )}
                      </>

                    )
                  ) : (
                    <div className="flex mt-3 justify-between w-full">
                      {isLoading ? (
                        <SpinnerKPI />
                      ) :
                        (
                          <p
                            className="text-[32px] text-zinc-700 font-bold hover:underline cursor-pointer"
                            onClick={
                              title === "Gross Profit Margin"
                                ? undefined
                                : toggleKPI
                            }
                          >
                            {data ? (
                              <p>
                                {title === "Gross Profit Margin" ? (
                                  <>
                                    {isCustomSymbol ? "" : currencySymbol}{" "}
                                    {selectedCurrentPeriodValue === null
                                      ? 0
                                      : (selectedCurrentPeriodValue * 100).toFixed(
                                        2
                                      )}
                                    {isCustomSymbol ? customSymbol : ""}{" "}
                                  </>
                                ) : (
                                  <>
                                    {isCustomSymbol ? "" : currencySymbol}{" "}
                                    {selectedCurrentPeriodValue === null
                                      ? "0"
                                      : Math.round(
                                        selectedCurrentPeriodValue
                                      ).toLocaleString(undefined)}
                                    {isCustomSymbol ? customSymbol : ""}{" "}
                                  </>
                                )}
                              </p>
                            ) : (
                              <p className="text-[12px] font-light text-grey italic">
                                No data found, please refresh or try later
                              </p>
                            )}

                            <div className="flex flex-col mt-3 gap-2 items-start justify-center w-full ">
                              {title === "Inventory Value" ? (
                                <></>
                              ) : (
                                <div className="flex flex-row justify-between w-full">
                                  <div className="flex flex-row items-center gap-2 w-full">
                                    <p className="">
                                      {data ? (
                                        <p
                                          className={`text-[10px] w-22 flex gap-1 flex-row items-center justify-center px-2 py-1 font-semibold rounded-xl ${title === "% of Orders Shipped Late"
                                              ? selectedChangePeriodValue < 0
                                                ? "bg-green-200 text-green-700"
                                                : "bg-red-200 text-red-700"
                                              : Math.abs(
                                                selectedChangePeriodValue
                                              ) < 0.001
                                                ? "bg-gray-300 text-gray-500"
                                                : selectedChangePeriodValue < 0
                                                  ? "bg-red-200 text-red-700"
                                                  : "bg-green-200 text-green-700"
                                            }`}
                                        >
                                          {selectedChangePeriodValue < 0 ? "-" : ""}
                                          {Math.abs(
                                            selectedChangePeriodValue * 100
                                          ).toFixed(1)}
                                          %
                                          {selectedChangePeriodValue < 0 ? (
                                            <BsArrowDownCircleFill size={16} />
                                          ) : (
                                            <BsFillArrowUpCircleFill size={16} />
                                          )}
                                        </p>
                                      ) : (
                                        <p className="text-[12px] font-light text-grey italic"></p>
                                      )}
                                    </p>
                                    <p className=" flex flex-row text-[11px] items-start text-gray-500 font-light">
                                      {data ? (
                                        <span className="pr-2 ">
                                          {isCustomSymbol ? "" : currencySymbol}{" "}
                                          {selectedCurrentPeriodValue !== null &&
                                            selectedPastPeriodValue !== null
                                            ? (title === "Gross Profit Margin"
                                              ? (
                                                (selectedCurrentPeriodValue -
                                                  selectedPastPeriodValue) *
                                                100
                                              ).toFixed(2)
                                              : selectedCurrentPeriodValue -
                                              selectedPastPeriodValue
                                            ).toLocaleString(undefined, {
                                              maximumFractionDigits: 0,
                                            })
                                            : "0"}
                                          {isCustomSymbol ? customSymbol : ""}{" "}
                                          Compared to {selectedValue}
                                        </span>
                                      ) : (
                                        <span className="pr-2"></span>
                                      )}
                                    </p>
                                  </div>
                                </div>
                              )}
                            </div>
                          </p>
                        )}
                    </div>
                  )}

                  <div className="flex flex-col gap-2   w-full">
                    <div className="flex flex-row justify-between w-full mt-3 gap-2 items-center">
                      <div className="flex flex-row items-center gap-2">
                        {/* <div className="rounded-full flex items-center justify-center  h-9 w-9 bg-slate-200">
                       <Tippy
                         theme="custom-white"
                         size="small"
                         placement="top"
                         content="Dear"
                       >
                         <img
                           className="rounded-full object-contain h-6 w-6"
                           src={dear}
                         />
                       </Tippy>
                     </div> */}
                      </div>

                      <div className="flex flex-row gap-2">
                        <AiFillHeart
                          size={18}
                          className="text-warning cursor-pointer"
                        />
                        <BiCommentDetail
                          size={18}
                          className="text-zinc-400 cursor-pointer hover:text-purple "
                        />
                      </div>
                    </div>
                    <div className="flex flex-wrap gap-1 mt-2">
                      {brandFilterSelected &&
                        brandFilterSelected.length > 0 &&
                        brandFilterSelected.map((selected, index) => (
                          <div
                            onClick={handleAdvanceDearFilters}
                            key={index}
                            className="flex-none  text-[10px] bg-orange-200 font-semibold text-orange-600 px-3 py-1 cursor-pointer rounded-md"
                          >
                            {selected.value}
                          </div>
                        ))}
                      {categoryFilter && (
                        <div className="flex-none  text-[10px] bg-neutral-200 font-semibold text-neutral-600 px-3 py-1 cursor-pointer rounded-md">
                          {categoryFilter}
                        </div>
                      )}
                      {contactFilterSelected &&
                        contactFilterSelected.length > 0 &&
                        contactFilterSelected.map((selected, index) => (
                          <div
                            onClick={handleAdvanceDearFilters}
                            key={index}
                            className="flex-none  text-[10px] bg-emerald-100 font-semibold text-emerald-500 px-3 py-1 cursor-pointer rounded-md"
                          >
                            {selected.value}
                          </div>
                        ))}
                      {customerTagsFilterSelected &&
                        customerTagsFilterSelected.length > 0 &&
                        customerTagsFilterSelected.map((selected, index) => (
                          <div
                            onClick={handleAdvanceDearFilters}
                            key={index}
                            className="flex-none  text-[10px] bg-emerald-200 font-semibold text-emerald-600 px-3 py-1 cursor-pointer rounded-md"
                          >
                            {selected.value}
                          </div>
                        ))}
                      {products &&
                        products.slice(0, showAll ? products.length : 2).map((selected, index) => (
                          <div
                            key={index}
                            className="flex-none text-[10px] bg-sky-100 font-semibold text-sky-500 px-2 py-1 cursor-pointer rounded-md"
                          >
                            {selected}
                          </div>
                        ))}

                      {/* Toggle Button with remaining count */}
                      {products.length > 2 && (
                        <Toggle
                          size="sm"
                          onClick={toggleShowAll}
                          variant="outline"
                          className="flex-none text-[10px] h-6   cursor-pointer rounded-md "
                        >
                          {showAll ? '− Show Less' : `+ Show More (${remainingCount} more) `}
                        </Toggle>
                      )}
                      {productTagsFilterSelected &&
                        productTagsFilterSelected.length > 0 &&
                        productTagsFilterSelected.map((selected, index) => (
                          <div
                            onClick={handleAdvanceDearFilters}
                            key={index}
                            className="flex-none  text-[10px] bg-sky-200 font-semibold text-sky-600 px-3 py-1 cursor-pointer rounded-md"
                          >
                            {selected.value}
                          </div>
                        ))}
                      {productTypeFilterSelected &&
                        productTypeFilterSelected.length > 0 &&
                        productTypeFilterSelected.map((selected, index) => (
                          <div
                            onClick={handleAdvanceDearFilters}
                            key={index}
                            className="flex-none  text-[10px] bg-violet-200 font-semibold text-violet-600 px-3 py-1 cursor-pointer rounded-md"
                          >
                            {selected.value}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoxCard02WithFilter;
